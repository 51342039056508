import React, { createContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

const defaultValues = {
  year: "2025",
  timezone: "CST",
  tagLine: "Some Tagline",
}

export const ConferenceContext = createContext(defaultValues)

// START of Conference Provider

export const ConferenceProvider = ({ children }) => {
  // let timezone = "123"
  const data = useStaticQuery(graphql`
    query conferenceContextQuery {
      sanityGeneral {
        timezone
        tagLine
        year
        fullDate
      }
    }
  `)

  const { timezone, year, tagLine, fullDate } = data.sanityGeneral

  return (
    <ConferenceContext.Provider
      value={{ ...defaultValues, timezone, year, tagLine, fullDate }}
    >
      {children}
    </ConferenceContext.Provider>
  )
}

// END of Conference Provider
