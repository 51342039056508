import React, { createContext, useState } from "react"

const defaultValues = {
  isAlertVisible: true,
  closeAlert: () => console.log("Close Alert!"),
}

export const AlertContext = createContext(defaultValues)

export const AlertProvider = ({ children }) => {
  const [isAlertVisible, setIsAlertVisible] = useState(
    defaultValues.isAlertVisible
  )

  const closeAlert = () => setIsAlertVisible(false)

  return (
    <AlertContext.Provider
      value={{ ...defaultValues, isAlertVisible, closeAlert }}
    >
      {children}
    </AlertContext.Provider>
  )
}
